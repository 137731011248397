<template>
    <div class="role_main">
        <!-- 标题 -->
        <div class="title_page">
            <div class="sig"></div>
            <div class="page_name">班级类型列表</div>
        </div>
        <!-- 表格内容 -->
        <div class="avatar_table">
            <el-card>
                <!-- 添加 删除 下拉框 按钮部分 -->
                <div class="out_sel_btn">
                    <div class="sel_inp">
                        <el-select v-model="campus_id" placeholder="校区" clearable @change="initData" size="mini">
                            <el-option v-for="item in campus_arr" :key="item.uid" :label="item.name" :value="item.uid">
                            </el-option>
                        </el-select>
                        <el-input placeholder="请输入班级类型" v-model="search_inp" clearable @change="initData">
                        </el-input>
                    </div>
                    <div class="add_del_btn">
                        <el-button type="primary" class="addBtn" @click="addition">添加</el-button>
                        <el-button class="delBtn" @click="deltion">删除</el-button>
                    </div>
                </div>
                <!-- 内容表格部分 -->
                <div class="table_init">
                    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                        @selection-change="handleSelectionChange"
                        :header-cell-style="{color:'#333333',background:'#FAFAFA'}">
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column prop="id" label="ID" width="180">
                        </el-table-column>
                        <el-table-column prop="campus_uid" label="校区名称">
                        </el-table-column>
                        <el-table-column prop="name" label="班级类型">
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <span style="margin-right:16px" @click="handle_Edit(scope.row.id)">编辑</span>
                                <span @click="handle_Delet(scope.row.id)">删除</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 分页部分 -->
                <Paging :count="count" :initData="initData" @changePage="handle_changePage"></Paging>
            </el-card>
        </div>
        <!-- 弹框部分 -->
        <!-- 添加编辑弹框 -->
        <el-dialog :visible.sync="add_edit_dialog" width="828px" :before-close="handleClose" @close="handle_reset">
            <!-- 标题 -->
            <div class="tipes">
                <div class="sd"></div>
                <div class="ax">{{title_name}}</div>
            </div>
            <!-- 表单验证内容 -->
            <el-form label-width="80px">
                <el-form-item label="校区名称">
                    <el-select v-model="school_name" placeholder="请输入校区名称" clearable size="mini">
                        <el-option v-for="item in school_name_arr" :key="item.uid" :label="item.name" :value="item.uid">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="班级类型">
                    <el-input v-model="class_name" placeholder="请输入班级类型" clearable size="mini"></el-input>
                </el-form-item>
            </el-form>
            <!-- 提交 重置 按钮 -->
            <div class="formBtn">
                <el-button type="primary" class="add_confirm" @click="handle_confirm">{{btn_name}}</el-button>
                <el-button class="add_Reset" @click="handle_reset">重置</el-button>
            </div>
        </el-dialog>
        <!-- 删除弹框 -->
        <el-dialog :visible.sync="del_dialog" width="567px" :before-close="handleClose" @close="remove_Fn">
            <!-- 标题 -->
            <div class="tipes">
                <div class="sd"></div>
                <div class="ax">删除</div>
            </div>
            <div class="del_content">{{del_tip}}</div>
            <!-- 提交 取消 按钮 -->
            <div class="formBtn">
                <el-button type="primary" class="del_confirm" @click="handle_del">确定</el-button>
                <el-button class="del_Reset" @click="remove_Fn">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Paging from '@/components/Paging';
import { getClassTyprApi, showClassTyprApi, addClassTyprApi, editClassTyprApi, delClassTyprApi } from '@/api'
export default {
    components: {
        Paging,
    },
    data () {
        return {
            // 登录用户id
            user_id: '',
            // 弹框显示隐藏
            add_edit_dialog: false,   //添加编辑弹框
            del_dialog: false,    //删除弹框
            // 每条数据id
            id: '',
            //  表格数据
            tableData: [],
            multipleSelection: [],
            // 分页
            page: 1,
            count: 0,
            max: 10,
            // 搜索输入框
            search_inp: '',
            // 搜索下拉框 校区
            campus_arr: [],
            campus_id: '',
            // 弹框标题  是添加还是编辑
            title_name: '',
            btn_name: '',
            // *********表单验证内容*********
            school_name_arr: [],
            school_name: '',   //校区名称
            class_name: '',   //班级名称
            // 删除数据的数组
            del_arr: [],
            // 删除提示语
            del_tip: '',
        }
    },
    created () {
        this.initData();
    },
    watch: {
        // 监听分页数量的变化
        count () {
            if (this.count == (this.page - 1) * this.max && this.count != 0) {
                this.page -= 1;
                this.initData()
            }
        },
    },
    methods: {
        // 子组件传过来的当前页数
        handle_changePage (data) {
            this.page = data;
        },
        // 初始化数据  
        initData () {
            this.user_id = sessionStorage.getItem('id');
            getClassTyprApi({
                page: this.page,   //当前页
                name: this.search_inp,  //校区名称
                campus_id: this.campus_id,     //校区id
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.tableData = res.data;
                    this.count = res.count;
                    this.campus_arr = res.campus;
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 表格的方法
        toggleSelection (rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange (val) {
            this.multipleSelection = val;
        },
        // 弹窗关闭
        handleClose () {
            this.add_edit_dialog = false;
            this.del_dialog = false;
        },
        // 点击添加按钮
        addition () {
            this.add_edit_dialog = true;
            this.title_name = '添加';
            this.btn_name = '确认添加';
            showClassTyprApi({
                type: 1,
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.school_name_arr = res.campus;    //校区
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 点击编辑按钮
        handle_Edit (id) {
            this.add_edit_dialog = true;
            this.id = id;
            this.title_name = '修改';
            this.btn_name = '确认修改';
            showClassTyprApi({
                id: id,
                type: 2,
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.school_name_arr = res.campus;    //校区
                    this.school_name = res.data.campus_uid;   //校区id
                    this.class_name = res.data.name;     //班级名称
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 添加  编辑 点击提交按钮
        handle_confirm () {
            if (this.title_name == '添加') {
                addClassTyprApi({
                    name: this.class_name,  //班级名称
                    campus_uid: this.school_name, //校区id
                    auth_userid: this.user_id, //登录用户id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('添加成功');
                        this.initData();
                        this.handle_reset();
                        this.add_edit_dialog = false;
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            } else if (this.title_name == '修改') {
                editClassTyprApi({
                    id: this.id,
                    name: this.class_name,  //班级名称
                    campus_uid: this.school_name, //校区id
                    auth_userid: this.user_id, //登录用户id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('修改成功');
                        this.initData();
                        this.handle_reset();
                        this.add_edit_dialog = false;
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
        // 添加  编辑  点击重置按钮  弹窗点击遮罩关闭
        handle_reset () {
            this.school_name = '';  //校区名称
            this.class_name = '';    // 班级名称
        },
        // 点击删除按钮
        handle_Delet (id) {
            this.id = id;
            this.del_dialog = true;
            this.del_tip = '确定删除此项？';
            this.del_arr.push(id);
        },
        // 多个删除
        deltion () {
            this.del_dialog = true;
            this.del_tip = '确定删除所选中的选项？';
            for (let i = 0; i < this.multipleSelection.length; i++) {
                let del_id = this.multipleSelection[i].uid;
                this.del_arr.push(del_id);
            };
        },
        remove_Fn () {
            this.del_dialog = false;
            this.del_arr.splice(0, this.del_arr.length)
        },
        // 删除点击确定按钮
        handle_del () {
            delClassTyprApi({
                id: this.del_arr,
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.$message.success('删除成功');
                    this.del_dialog = false;
                    this.initData();
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.role_main {
    width: 100%;
    height: 100%;
    // 页面标题部分
    .title_page {
        text-align: left;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        .sig {
            width: 18px;
            height: 4px;
            margin-right: 8px;
            background: #2479dd;
        }
        .page_name {
            font-size: 18px;
            font-weight: 600;
        }
    }
    // 表格显示内容
    .avatar_table {
        margin-bottom: 20px;
        // 下拉框  添加  删除按钮部分
        .out_sel_btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // 下拉框部分
            .sel_inp {
                margin-top: 15px;
                display: flex;
                .el-select {
                    width: 140px;
                    height: 30px;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                    ::v-deep.el-input__inner {
                        width: 140px;
                        height: 30px;
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                        ::v-deep.el-select__caret {
                            line-height: 30px;
                        }
                        ::v-deep.el-input__icon {
                            line-height: 30px;
                        }
                    }
                }
                .el-input {
                    width: 155px;
                    height: 30px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    border-left: 0px;
                    ::v-deep.el-input__inner {
                        width: 155px;
                        height: 30px;
                        border-top-left-radius: 0px;
                        border-bottom-left-radius: 0px;
                        border-left: 0px;
                    }
                }
            }
            // 添加  删除按钮部分
            .add_del_btn {
                text-align: right;
                .el-button {
                    width: 80px;
                    height: 30px;
                    line-height: 4px;
                }
                .addBtn {
                    margin-right: 8px;
                }
            }
        }
        // 表格内容
        .table_init {
            margin-top: 20px;
            text-align: center;
            // 操作按钮
            span {
                border: none;
                width: 24px;
                height: 18px;
                font-size: 12px;
                font-weight: 400;
                cursor: pointer;
            }
        }
    }
    // 弹框部分
    .el-dialog {
        text-align: center;
        // border-radius: 12px;
        // 标题部分
        .tipes {
            display: flex;
            align-items: center;
            text-align: left;
            position: absolute;
            left: 30px;
            top: 30px;
            .sd {
                width: 18px;
                height: 4px;
                margin-right: 8px;
                background: #2479dd;
            }
            .ax {
                color: #000000;
                font-size: 16px;
                font-weight: 600;
            }
        }
        //  添加  编辑 表单部分
        .el-form {
            text-align: left;
            margin: 30px 0 50px 100px;
            .el-form-item {
                .el-input {
                    width: 472px;
                    height: 30px;
                }
                ::v-deep.el-input__inner {
                    width: 472px;
                    height: 30px;
                }
            }
        }
        // 删除弹框
        .del_content {
            font-size: 18px;
            font-weight: 400;
            margin: 68px 0 80px;
        }
        // 提交  重置  按钮部分
        .formBtn {
            margin-bottom: 20px;
            .el-button {
                width: 98px;
                height: 40px;
                font-size: 14px;
                font-weight: 400;
            }
            // 确认按钮  添加  编辑
            .add_confirm {
                margin-right: 16px;
            }
            // 确认按钮  删除
            .del_confirm {
                margin-right: 16px;
            }
        }
    }
}
</style>